<script setup lang="ts">
import {useWp} from "~/composables/Redmonkey/index";
import type {WpMedia} from "~/integration/wordpress/types";
import type {PropType} from "vue";
import MenuItem from "~/components/Redmonkey/Header/MenuItem.vue";
import Logo from "~/components/Redmonkey/Header/Logo.vue";

const {
  formatPhoneToTel
} = useWp();

const props = defineProps({
  menu: {
    type: Object
  },
  logo: {
    type: Object as PropType<WpMedia>
  },
  phone: {
    type: String
  }
})

const getMenuBefore = () => {
  return props.menu?.beforeLogo?.menuItems?.nodes || [];
}

const getMenuAfter = () => {
  return props.menu?.afterLogo?.menuItems?.nodes || [];
}

const mobileMenu = ref(false);

const route = useRoute();

watch(
    () => route.params.slug,
    async (newslug) => {
      mobileMenu.value = false
    },
);
</script>
<template>
  <header>
    <div class="big-container">
      <div class="header-content flex wrap justify middle">
        <div class="menu flex wrap center middle">
          <div class="menu-items menu-before flex wrap justify middle hide-mobile" v-if="getMenuBefore().length">
            <template v-for="item in getMenuBefore()" :key="item.label">
              <MenuItem :item="item"/>
            </template>
          </div>
          <Logo :logo="logo" />
          <div class="menu-items menu-after flex wrap justify middle hide-mobile" v-if="getMenuAfter().length">
            <template v-for="item in getMenuAfter()" :key="item.label">
              <MenuItem :item="item"/>
            </template>
          </div>
        </div>
        <div class="phone-box hide-mobile" v-if="phone">
          <a :href="formatPhoneToTel(phone)">
            {{ phone }}
          </a>
        </div>
        <div class="mobile-menu-open hide-desktop">
          <span class="opener" @click="mobileMenu = true"></span>
        </div>
      </div>
      <div class="mobile-menu hide-desktop" v-if="mobileMenu">
        <div class="mobile-menu-content">
          <div class="mobile-menu-header flex wrap justify">
            <Logo :logo="logo" @click="mobileMenu = false"/>
            <div class="mobile-closer">
              <span class="closer" @click="mobileMenu = false"></span>
            </div>
          </div>
          <div class="menu-wrap">
            <div class="menu-items">
              <template v-for="item in [...getMenuBefore(), ...getMenuAfter()]" :key="item.label">
                <MenuItem :item="item" :marker="false" @click="mobileMenu = false"/>
              </template>
            </div>
            <div class="phone-box" v-if="phone">
              <a class="action primary" :href="formatPhoneToTel(phone)">
                Подзвонити Нам
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>