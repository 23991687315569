<script setup lang="ts">
import {useWp} from "~/composables/Redmonkey";
import type {PropType} from "vue";
import type {WpMedia} from "~/integration/wordpress/types";

defineProps({
  logo: {
    type: Object as PropType<WpMedia>
  },
});

const {
  getMediaUrlFromMedia,
  getMediaAltFromMedia,
  getMediaWidthFromMedia,
  getMediaHeightFromMedia,
} = useWp();
</script>
<template>
  <div class="logo" v-if="getMediaUrlFromMedia(logo)">
    <nuxt-link to="/">
      <img
          :width="getMediaWidthFromMedia(logo)"
          :height="getMediaHeightFromMedia(logo)"
          :src="getMediaUrlFromMedia(logo)"
          :alt="getMediaAltFromMedia(logo)"
      />
    </nuxt-link>
  </div>
</template>