<template>
  <div class="top-bar-content">
    <component :is="getLinkUrl(link) ? 'a' : 'div'" :href="getLinkUrl(link)" :target="getLinkTarget(link)">
      <span v-html="content"></span>
    </component>
  </div>
</template>
<script setup>
import {useAcf} from "~/composables/Redmonkey/index";

defineProps({
  content: {
    type: String
  },
  link: {
    type: [Object, null, undefined]
  }
})

const {
  getLinkTarget,
  getLinkUrl
} = useAcf();
</script>