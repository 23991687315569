import type {AcfLink} from "~/integration/wordpress/types";

export default () => {
    const getLinkUrl = (link: AcfLink) => {
        return link?.url;
    }

    const getLinkTarget = (link: AcfLink) => {
        return link?.target;
    }

    const getLinkTitle = (link: AcfLink) => {
        return link?.title;
    }

    return {
        getLinkUrl,
        getLinkTarget,
        getLinkTitle
    }
}