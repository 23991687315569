<script setup lang="ts">
import type {PropType} from "vue";
import type {WpMenuItem} from "~/integration/wordpress/types";
import {useWp} from "~/composables/Redmonkey";
import {NuxtLink} from "#components";
import Marker from "~/components/Redmonkey/Base/Marker.vue";

const {isExternalLink} = useWp();

const props = defineProps({
  item: {
    type: Object as PropType<WpMenuItem>
  },
  marker: {
    type: Boolean,
    default: true
  }
});

const hasSubMenu = () => {
  if (!props.item?.childItems) return false;
  return props.item?.childItems?.nodes?.length > 0;
}

const subMenuClick = ref(false);

</script>
<template>
  <div class="menu-item" v-if="item && !item.parentId">
    <component
        :is="isExternalLink(item.url) ? 'a' : item.url !== '#' ? NuxtLink : 'div'"
        :href="item.url"
        :to="item.url"
        :target="item.target"
        :class="{'has-child': hasSubMenu(), 'without-marker': !marker}"
    >
      <Marker v-if="marker">
        <span class="menu-link">{{ item.label }}</span>
      </Marker>
      <span v-else class="menu-link" @click="subMenuClick = !subMenuClick">{{ item.label }}</span>

      <div class="sub-menu" :class="{'_active': subMenuClick}" v-if="hasSubMenu()">
        <client-only>
          <template v-for="child in item.childItems.nodes" :key="child.label">
            <MenuItem
                :item="child"
                :marker="false"
            />
          </template>
        </client-only>
      </div>
    </component>
  </div>
</template>