<script setup lang="ts">
import Notifications from '~/components/Redmonkey/Base/Notifications/Notifications.vue'
import {useSettingsStore} from "~/stores/settings";
import type {Settings, WP} from "~/integration/wordpress/types";
import TopBar from "~/components/Redmonkey/Header/TopBar.vue";
import Header from "~/components/Redmonkey/Header/Header.vue";
import {NuxtPwaManifest} from "#components";

const settingsStore = useSettingsStore();
const {$vwf, $config} = useNuxtApp();

const DemoForm = defineAsyncComponent(() => import("~/components/Redmonkey/Footer/DemoForm.vue"))
const Footer = defineAsyncComponent(() => import("~/components/Redmonkey/Footer/Footer.vue"))

const {data: fetchData} = await useAsyncData('settings', async () => {
  const [settingsData, menuData, identifyData] = await Promise.all([
    ($vwf as WP).$wordpress.api.getThemeSettings(),
    ($vwf as WP).$wordpress.api.getMenu(),
    ($vwf as WP).$wordpress.api.getIndentify()
  ])

  if (settingsData?.themeGeneralSettings) {
    settingsStore.$patch((state) => {
      state.themeSettings = settingsData?.themeGeneralSettings?.themeSettings;
    });
  }

  if (settingsData?.redmonkeyGeneralSettings) {
    settingsStore.$patch((state) => {
      state.redmonkeySettings = settingsData?.redmonkeyGeneralSettings?.redmonkeySettings;
    });
  }

  return {
    settings: settingsData?.themeGeneralSettings,
    menu: menuData,
    indetify: identifyData
  }
});

const {settings, menu, indetify} = fetchData.value as any;

const isTopBar = () => {
  return (settings as Settings)?.themeGeneral?.headerTopBar;
}

const getFaviconType = (url: string) => {
  if (!url) return '';

  const extension = url?.split('.')?.pop()?.toLowerCase();

  // Map known extensions to MIME types
  const mimeTypes = {
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'bmp': 'image/bmp',
    'webp': 'image/webp',
    'svg': 'image/svg+xml',
    'ico': 'image/x-icon',
    // Add more as needed
  };

  //@ts-ignore
  return mimeTypes[extension as string] || '';
}

const favicon = indetify?.favicon?.nodes?.[0]?.mediaItemUrl;
const logo = indetify?.logo?.nodes?.[0];
const phone = settings?.themeGeneral?.phoneNumber;

useHead({
  link: [
    {rel: 'icon', type: getFaviconType(favicon), href: favicon},
    {
      rel: 'preload',
      as: 'style',
      href: `${$config.public.wpContentPlugins}elementor/assets/css/frontend-lite.min.css`,
      id: 'elementor-frontend-css-preload',
      hid: 'elementor-frontend-css-preload',
    },
    {
      rel: 'preload',
      as: 'style',
      href: `${$config.public.wpContentUploads}elementor/css/global.css`,
      id: 'elementor-global-css-preload',
      hid: 'elementor-global-css-preload',
    },
    {
      rel: 'preload',
      as: 'style',
      href: `${$config.public.wpContentUploads}elementor/css/post-7.css`,
      id: `elementor-post-7-css-preload`,
      hid: `elementor-post-7-css-preload`
    }
  ],
})
</script>

<template>
  <div class="page-wrapper default-template">
    <NuxtPwaManifest/>
    <div class="top-bar" v-if="isTopBar()">
      <TopBar
          :content="settings.themeGeneral.headerTopBar"
          :link="settings.themeGeneral.headerTopBarLink"
      />
    </div>
    <div class="header">
      <Header
          :menu="menu"
          :logo="logo"
          :phone="phone"
      />
    </div>
    <div class="page-content">
      <slot/>
    </div>
    <div id="demo-form">
      <DemoForm/>
    </div>
    <div class="notifications-wrapper">
      <client-only>
        <Notifications/>
      </client-only>
    </div>
    <div class="footer">
      <Footer
          :logo="logo"
          :menu="menu"
          :settings="settings"
      />
    </div>
  </div>
</template>
<style lang="scss">
@import "assets/css/source/index";

body {
  margin-bottom: -8px;
}
</style>