<script setup lang="ts">
import {useNotifications} from "../../../../composables/Redmonkey";

const {isNotifications, notifications} = useNotifications();

const mergedNotifications = ref([]);

const mergeNotifications = () => {
  const merged = {};
  notifications.value.forEach(notification => {
    const {id, type, message, action, dismiss} = notification;
    if (!merged[type]) {
      merged[type] = {
        id,
        type,
        message: [],
        action: action && {...action},
        dismiss,
      };
    }
    merged[type].message.push(message);
  });

  return Object.keys(merged).map(type => {
    const mergedNotification = merged[type];
    mergedNotification.message = mergedNotification.message.join('.\n\n');
    return mergedNotification;
  });
};

watch(notifications, () => {
  mergedNotifications.value = mergeNotifications();
});
</script>
<template>
  <div class="notifications" v-show="isNotifications">
    <div class="notifications-items">
      <template v-for="notification in mergedNotifications" :key="notification.id">
        <div
            class="notifications-item"
            @click:close="notification.dismiss"
            @click:action="notification.action && notification.action.onClick()"
            :class="`notifications-${notification.type}`"
        >{{ notification.message }}
        </div>
      </template>
    </div>
  </div>
</template>